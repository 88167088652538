import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsultaCepService {

  constructor(private http: HttpClient) { }

  consultaCEP(cep: string) {
    // Nova variável CEP somente com dígitos
    cep = cep.replace(/\D/g, '');
    // Verifica se o c ampo CEP possui valor informado
    if (cep !== '') {
      // Expressão regular para validar cep
      const validaCEP = /^[0-9]{8}$/;
      // Valida o formato do CEP
      if (validaCEP.test(cep)) {
        return this.http.get(`//viacep.com.br/ws/${cep}/json`);
      }
    }

    return of({});
  }
}
