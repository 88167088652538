import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DataAtualService {

  constructor(private datePipe: DatePipe) { }

  retorna() {
    const dataAtual = new Date();
    const data = this.datePipe.transform(dataAtual, 'yyyy-MM-dd');
    return data;
  }

  retornaUltimoDia() {
    const dataAtual = new Date();
    const mes = dataAtual.getMonth() + 1;
    const dt = (new Date(2020, mes, 0)).getDate();
    let data = dataAtual.getFullYear() + '-' + mes + '-' + dt;
    data = this.datePipe.transform(data, 'yyyy-MM-dd');
    return data;
  }

  retornaPrimeiroDia() {
    const dataAtual = new Date();
    const mes = dataAtual.getMonth() + 1;
    let data = dataAtual.getFullYear() + '-' + mes + '-' + 1;
    data = this.datePipe.transform(data, 'yyyy-MM-dd');
    return data;
  }

  retornaMes() {
    let nomeMes = '';
    const dataAtual = new Date();
    const mes = dataAtual.getMonth() + 1;
    if (mes === 5) {
      nomeMes = 'Maio' + '/' + dataAtual.getFullYear();
    } else if (mes === 6) {
      nomeMes = 'Junho' + '/' + dataAtual.getFullYear();
    } else if (mes === 7) {
      nomeMes = 'Julho' + '/' + dataAtual.getFullYear();
    }

    return nomeMes;
  }

}
