import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/utils/_modal/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-principal',
  templateUrl: './dashboard-principal.component.html',
  styleUrls: ['./dashboard-principal.component.css']
})
export class DashboardPrincipalComponent implements OnInit {

  constructor(private modalService: ModalService, private router: Router,) { }

  ngOnInit(): void {
  }

  openModal() {
    this.modalService.open('modalFiltros');
  }

  closeModal() {
    this.modalService.close('modalFiltros');
  }
  abreEntradas() {
    this.router.navigate(['entradas']);
  }

  abreProdutos() {
    this.router.navigate(['produto']);
  }

  abreSaidas() {
    this.router.navigate(['saidas']);
  }

  abreClientes() {
    this.router.navigate(['cliente']);
  }

  abreAdministrativo() {
    this.router.navigate(['administrativo']);
  }

  abreFechamento() {
    this.router.navigate(['fechamento']);
  }
}
