import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';

import { URL_API } from '../../../app.api';
import { User } from '../models/user.model';
import { Router } from '@angular/router';

export const TOKEN_NAME = 'smartgestor-token';

@Injectable()
export class AuthService {

    loadingActive = false;

    currentUser: User;
    returnToken: any;

    constructor(private http: HttpClient, private router: Router) { }

    getUser(): User {
        return JSON.parse(sessionStorage.getItem(TOKEN_NAME));
    }

    verificarPermissao(permissao: string): boolean {
        return this.currentUser && this.currentUser.permissoes[0][permissao] && this.currentUser.permissoes[0][permissao] === 'S';
    }

    validateToken() {
        let returnValidateToken = false;
        this.currentUser = this.getUser();

        if (this.currentUser !== null) {
            const expiration = '2025-06-12 16:25:45';
            const dateExpiration = new Date(expiration);
            // const dateExpiration = new Date(this.currentUser.expiration);
            const dateNow = new Date();

            if (dateExpiration > dateNow) {
                returnValidateToken = true;
            }
        }

        return returnValidateToken;
    }

    public login(email: string, password: string) {
        return this.getToken(email, password)
            .pipe(map(res => res));
    }

    getToken(email: string, password: string) {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.set('email', email);
        urlSearchParams.set('password', password);

        const body = urlSearchParams.toString();

        return this.http.post(`${URL_API}/usuarios/apiLoginUsuario.php`,
            {
                usuario: email,
                senha: password
            },
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            }
        ).pipe(
            map(res => res),
            catchError((err, caught) => {
                return throwError(err);
            })
        );
    }

    private setToken(authResult) {
        sessionStorage.setItem(TOKEN_NAME, JSON.stringify(authResult));
    }

    logout() {
        sessionStorage.removeItem(TOKEN_NAME);
        this.router.navigate(['/auth/login']);
    }

}
