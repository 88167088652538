import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthService, TOKEN_NAME } from '../services/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '../models/user.model';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    user: User;

    constructor(
        public authService: AuthService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes('viacep.com.br') && !request.url.includes('receitaws.com.br') && !request.url.includes('apiLoginUsuario')) {
            this.user = JSON.parse(sessionStorage.getItem(TOKEN_NAME));

            const headers = new HttpHeaders({
                // 'Authorization': `Bearer ${this.user.token}`,
                // 'Content-Type': 'application/json',
            });
            const cloneReq = request.clone({ headers });

            return next.handle(cloneReq).pipe(catchError(err => {
                if (err.status === 401) {
                    this.authService.logout();
                } else if (err.status === 403) {
                    this.router.navigate(['/not-permission']);
                }
                return throwError(err);
            }));
        }
        return next.handle(request);
    }
}
