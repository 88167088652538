<div class="animated fadeInDown">
    <div class="tela">
        <i class="fas fa-question" id="filtro"></i>
        <br>
        <br>
        <div mat-dialog-content>
            <b>{{message}}</b>
        </div>
        <hr>
        <div mat-dialog-actions>
            <button mat-raised-button color="primary" [mat-dialog-close]="true">Confirmar</button>
            <button mat-raised-button color="warn" (click)="onClickNO()">Cancelar</button>
        </div>

    </div>

</div>