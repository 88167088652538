import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../shared/utils/_modal';
import { DashboardPrincipalComponent } from './dashboard-principal/dashboard-principal.component';

@NgModule({
  declarations: [DashboardPrincipalComponent],
  imports: [
    CommonModule,
    ModalModule
  ]
})
export class DashboardModule { }
