import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localept from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask';
import { AngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/shared/guards/auth.guard';
import { TokenInterceptor } from './auth/shared/interceptors/token.interceptor';
import { AuthService } from './auth/shared/services/auth.service';
import { ConsultaCepService } from './shared/services/consulta-cep.service';
import { ConsultaCnpjService } from './shared/services/consulta-cnpj.service';
import { ConsultaCpfService } from './shared/services/consulta-cpf.service';
import { DataAtualService } from './shared/services/data-atual.service';

import { FlexLayoutModule } from '@angular/flex-layout';
import { DashboardModule } from './dashboard/dashboard.module';
import { ModalModule } from './shared/utils/_modal';
import { ConfirmComponent } from './shared/utils/confirm/confirm.component';
registerLocaleData(localept, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    ConfirmComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxMaskModule.forRoot(),
    DashboardModule,
    ModalModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    HttpClient,
    DatePipe,
    ConsultaCepService,
    ConsultaCpfService,
    ConsultaCnpjService,
    DataAtualService,
    { provide: LOCALE_ID, useValue: 'pt' }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
