import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

    constructor(
        private authService: AuthService,
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.authService.validateToken()) {
            return true;
        } else {
            this.authService.logout();
            this.router.navigate(['/auth/login']);
            return false;
        }
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.validateToken()) {
            return true;
        } else {
            this.authService.logout();
            this.router.navigate(['/auth/login']);
            return false;
        }
    }

    canDeactivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return window.confirm('Tem certeza que deseja sair dessa página?');
    }
}
