import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardPrincipalComponent } from './dashboard/dashboard-principal/dashboard-principal.component';
import { AuthGuard } from './auth/shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'dashboard',
    component: DashboardPrincipalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cliente',
    loadChildren: () => import('./cadastros/clientes/clientes.module').then(m => m.ClientesModule),
  },
  {
    path: 'fornecedor',
    loadChildren: () => import('./cadastros/fornecedores/fornecedores.module').then(m => m.FornecedoresModule),
  },
  {
    path: 'produto',
    loadChildren: () => import('./cadastros/produtos/produtos.module').then(m => m.ProdutosModule),
  },
  {
    path: 'usuario',
    loadChildren: () => import('./cadastros/usuarios/usuarios.module').then(m => m.UsuariosModule),
  },

  {
    path: 'administrativo',
    loadChildren: () => import('./contas/administrativo/administrativo.module').then(m => m.AdministrativoModule),
  },

  {
    path: 'fechamento',
    loadChildren: () => import('./movimentacao/fechamento/fechamento.module').then(m => m.FechamentoModule),
  },
  {
    path: 'saidas',
    loadChildren: () => import('./movimentacao/saidas/saidas.module').then(m => m.SaidasModule),
  },
  {
    path: 'entradas',
    loadChildren: () => import('./movimentacao/entradas/entradas.module').then(m => m.EntradasModule),
  },
  {
    path: 'analiticos',
    loadChildren: () => import('./analitico/analitico.module').then(m => m.AnaliticoModule),
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
