<!-- Toolbar login and register -->
<mat-toolbar *ngIf="!this.authService.validateToken()" class="app-header">
  <div><img src="/assets/logoSmartGestor.png" alt="" width="200px" height="60px" style="padding-left: 25px;"></div>
  <span class="nav-tool-items">
    <a mat-button routerLink="auth/login" routerLinkActive="active">Login</a>
    <!-- <a mat-button mat-raised-button routerLink="auth/register" routerLinkActive="active">Registrar</a> -->
  </span>
</mat-toolbar>

<app-login *ngIf="!this.authService.validateToken()"></app-login>

<!-- Toolbar logged    target="_blank"-->
<mat-toolbar *ngIf="this.authService.validateToken()">
  <!--color='primary'-->
  <mat-toolbar-row class="mat-elevation-z6">
    <button (click)="sidenav.toggle()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <div>
      <a routerLink="/dashboard" class="title-company" style="margin-left: 15px;">
        <img src="/assets/logoSmartGestor.png" alt="" width="200px" height="60px" style="padding-left: 25px;">
      </a>
    </div>
    <span class="menu-spacer"></span>

    <div class="animated zoomIn">
      <div style="margin-left: 80px;">
        {{ authService.currentUser.result[0].nomeEmpresa }}
      </div>
    </div>

    <span style="flex: 1 1 auto;"></span>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example user verified icon"
      [matMenuTriggerFor]="menu">account_circle</mat-icon>
    <button mat-button [matMenuTriggerFor]="menu">{{ authService.currentUser.result[0].nome }}</button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>Perfil</button>
      <button mat-menu-item (click)="authService.logout()">Sair</button>
    </mat-menu>
  </mat-toolbar-row>

</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          <i class="fas fa-address-card mr-2 fa-lg"></i> Cadastro
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p *ngIf="authService.verificarPermissao('consultaClientes')">
        <i class="fas fa-arrow-circle-right mr-2 fa-lg" style="color: gray; margin-left: 20px;"></i>
        <a (click)="sidenav.toggle()" routerLink="/cliente">Clientes</a>
      </p>
      <p *ngIf="authService.verificarPermissao('consultaProdutos')">
        <i class="fas fa-arrow-circle-right mr-2 fa-lg" style="color: gray; margin-left: 20px;"></i>
        <a (click)="sidenav.toggle()" routerLink="/produto">Produtos</a>
      </p>
      <p *ngIf="authService.verificarPermissao('consultaFornecedores')">
        <i class="fas fa-arrow-circle-right mr-2 fa-lg" style="color: gray; margin-left: 20px;"></i>
        <a (click)="sidenav.toggle()" routerLink="/fornecedor">Fornecedores</a>
      </p>
      <p *ngIf="authService.verificarPermissao('usuarios')">
        <i class="fas fa-arrow-circle-right mr-2 fa-lg" style="color: gray; margin-left: 20px;"></i>
        <a (click)="sidenav.toggle()" routerLink="/usuario">Usuários</a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          <i class="fas fa-address-card mr-2 fa-lg"></i> Movimentação
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- <p *ngIf="authService.verificarPermissao('analiticos')">
        <i class="fas fa-arrow-circle-right mr-2 fa-lg" style="color: gray; margin-left: 20px;"></i>
        <a (click)="sidenav.toggle()" routerLink="/analitico">Analíticos</a>
      </p> -->
      <p *ngIf="authService.verificarPermissao('entradas')">
        <i class="fas fa-arrow-circle-right mr-2 fa-lg" style="color: gray; margin-left: 20px;"></i>
        <a (click)="sidenav.toggle()" routerLink="/entradas">Entradas</a>
      </p>
      <p *ngIf="authService.verificarPermissao('fazerFechamento')">
        <i class="fas fa-arrow-circle-right mr-2 fa-lg" style="color: gray; margin-left: 20px;"></i>
        <a (click)="sidenav.toggle()" routerLink="/fechamento">Fechamento</a>
      </p>
      <p *ngIf="authService.verificarPermissao('inclusaoSaidas')">
        <i class="fas fa-arrow-circle-right mr-2 fa-lg" style="color: gray; margin-left: 20px;"></i>
        <a (click)="sidenav.toggle()" routerLink="/saidas">Saídas</a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          <i class="fas fa-address-card mr-2 fa-lg"></i> Contas
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p *ngIf="authService.verificarPermissao('administrativo')">
        <i class="fas fa-arrow-circle-right mr-2 fa-lg" style="color: gray; margin-left: 20px;"></i>
        <a (click)="sidenav.toggle()" routerLink="/administrativo">Administrativo</a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          <i class="fas fa-address-card mr-2 fa-lg"></i> Analíticos
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p *ngIf="authService.verificarPermissao('analiticos')">
        <i class="fas fa-arrow-circle-right mr-2 fa-lg" style="color: gray; margin-left: 20px;"></i>
        <a (click)="sidenav.toggle()" routerLink="/analiticos">Relatórios</a>
      </p>
    </mat-expansion-panel>


    <mat-nav-list>
      <a mat-list-item (click)="sidenav.toggle()" mat-list-item> <i class="fas fa-address-card mr-2 fa-lg"
          style="color: gray;"></i>
        Fechar</a>
    </mat-nav-list>

    <br>
    <br>
    <img src="/assets/logoSmartGestor.png" alt="" width="200px" height="90px" style="padding-left: 35px;">


  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>