<div class="menu"><img src="assets/acessorapido.png" alt="" width="100px" height="100px">
    <ul class="anel">
        <li (click)="abreClientes()"><a title="Acesso rápido a Clientes"><i class="fas fa-address-card"></i></a>
        </li>
        <li (click)="abreProdutos()"><a title="Acesso rápido a Produtos"><i class="fas fa-barcode"></i></a></li>
        <li (click)="abreEntradas()"><a title="Acesso rápido a Entradas"><i class="fas fa-truck"></i></a></li>
        <li (click)="abreAdministrativo()"><a title="Acesso rápido a Administrativo"><i class="fa fa-chart-pie"></i></a>
        </li>
        <li (click)="abreSaidas()"><a title="Acesso rápido a Saídas"><i class="fas fa-cart-plus"></i></a></li>
        <li (click)="abreFechamento()"><a title="Acesso rápido a Fechamento "><i class="fas fa-print"></i></a></li>
    </ul>
</div>
<br>
<br>
<br>
<br>
<br>

<div class="rodape">
    Sistema de Controle de vendas : smartGestor 3.0
</div>